const TextEditorExample = [
  {
    0: "If you're trying to do something more grounded and in the realistic realism sense, it's really helpful to be able to illustrate or be able to practice from not only photos, but also real life. I think a lot of building a foundation starts from drawing from really basic objects, which you see a lot. But personally, for me, I think I started off drawing a lot from photographs and painting a lot from photographs and just sort of starting to see how shapes and colors align in an image. I think drawing from real life is definitely a lot more helpful than drawing from photos because there's something about the dimensionality of real life object that is hard to flatten. Like, for example, if you're drawing from a photograph onto another paper, it's really easy to just transfer. Oh, the line goes here, the line goes there. But if you're looking at a full object, you have to sort of do that translation in your head by yourself. And that can be a bit harder. But I think once you've mastered that, it makes it a lot easier to draw something from imagination, because then you have a basis of how do I want to represent this idea that I formulated my head, whether it be in words or sort of like a piece together image. This way, it's easier to sort of get understanding what structure, sort of exists or have a better sense of visualization.",
    1: "If you're trying to do something more grounded and in the realistic realism sense, it's really helpful to be able to illustrate or be able to practice from not only photos, but also real life.I think a lot of building a foundation starts from drawing from really basic objects, which you see a lot.But personally, for me, I think I started off drawing a lot from photographs and painting a lot from photographs and just sort of starting to see how shapes and colors align in an image.I think drawing from real life is definitely a lot more helpful than drawing from photos because there's something about the dimensionality of real life object that is hard to flatten. Like, for example, if you're drawing from a photograph onto another paper, it's really easy to just transfer. Oh, the line goes here, the line goes there. But if you're looking at a full object, you have to sort of do that translation in your head by yourself.And that can be a bit harder.But I think once you've mastered that, it makes it a lot easier to draw something from imagination, because then you have a basis of how do I want to represent this idea that I formulated my head, whether it be in words or sort of like a piece together image. This way, it's easier to sort of get understanding what structure, sort of exists or have a better sense of visualization.",
    2: "placeholder",
    3: "placeholder",
    4: "placeholder",
    id: "a91ec4d7-b676-4c92-b576-5ee51aec51f4",
    keywords: [],
  },
];

export default TextEditorExample;
