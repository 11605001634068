import React, { Component, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import rake from "rake-js";
import debounce from "lodash/debounce";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  Settings,
  Cancel,
  CallMerge,
  AutoFixHigh,
  Mic,
  Undo,
  Redo,
  // LegendToggle
  ContentCut
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";

import RambleBox from "./RambleBox";
import withDictation from "./Dictation";
import exampleBoxes from "./exampleBoxes";
import TextEditorExample from "./TextEditorStudyExample";
import IDExample from "./iterativeDraftingExample";
import llmapi from "./llmapi";
import historyapi from "./historyapi";
import stopWordSet from "./stopWords";
import { downloadContent } from "./utils";
import { useSearchParams } from "react-router-dom";
import { LongAndShortPressButton } from "./LongAndShortPressButton";

class RambleEditor extends Component {
  constructor(props) {
    // props should contain fileId, plus get/setRambles functions, and get/setTitle functions.
    super(props);
    this.defaultState = {
      title: "New Topic",
      rambleBoxes: [],
      currentMessageSummary: "",
      UIVersion: "C", // A=speech/keyboard only, B=noLLM, C=LLM
      activeRambleBox: -1,
      editingRambleBox: -1,
      respeakingRambleBox: -1,
      areBlockToolsEnabled: true, // controls if we have ramble boxes
      areLLMToolsEnabled: true, // controls if LLM tools are enabled
      isSettingsModalOpen: false,
      isCustomPromptModalOpen: false,
      viewLevel: 1, // 0 = full text, 1 = cleaned, 4 = shortest summary
      snackOpen: false,
      snackText: "",
      severity: "info",
      LLMcommandTargetId: -1,
      mergingBoxIds: new Set(),
      autoMergeBoxIds: new Set(),
      customPrompt: "",
      cursorPosition: -1, // for when the UI:A textarea is unfocused
      mostRecentLLMUpdate: new Map(), // Map of boxId + level to timestamp
      useKeywordsWithCustomPrompt: false,
      llmOrManualMod: new Map(), // set of ramblebox ids -> timestamp that were just created by merge (auto or manual) or split (auto or manual)
      lastClean: new Map(), // set of ramblebox ids -> last clean 1
      isCustomPromptRecording: false,
      undoStack: [],
      redoStack: [],
      showSlidingWindow: false,
      isSemanticSplitModalOpen: false,
      semanticSplitCustomPrompt: "",
      useKeywordsWithSemanticSplit: false,
      isSemanticSplitPromptRecording: false,
      semanticSplitTarget: -1,

      isSemanticMergeModalOpen: false,
      semanticMergeCustomPrompt: "",
      useKeywordsWithSemanticMerge: false,
      isSemanticMergePromptRecording: false,
    };
    historyapi.context = { UIVersion: this.defaultState.UIVersion };

    this.state = this.defaultState;
    this.baseMessage = `Speak to enter text here!`;

    this.fastSummaryPipeline = llmapi.pipeline(llmapi.fastSummaryAtLevel, (summary) =>
      this.setState({ currentMessageSummary: summary })
    );
  }

  componentDidMount() {
    // console.log("Ramble Editor Mounted", this.props);
    // Start fresh (but save localStorage)
    this.clearData({ clearLocal: false });

    // Load in the saved version of the UI
    let UIVersion = localStorage.getItem("UIVersion");
    if (UIVersion === null || UIVersion === "undefined") UIVersion = "C"; // Default, enable LLM/Block tools
    this.updateUIVersion({ target: { value: UIVersion } });

    // See if we have a saved state/title
    // const savedState = localStorage.getItem("rambleBoxes");
    // const savedState = this.props.getRambles();
    const rambleBoxes = this.props.getRambles(); // JSON.parse(savedState);
    // console.log("Ramble Boxes", rambleBoxes);
    if (rambleBoxes) this.setState({ rambleBoxes });
    // const savedTitle = localStorage.getItem("rambleTitle");
    const savedTitle = this.props.getTitle();
    if (savedTitle) {
      this.setState({ title: savedTitle });
      document.title = savedTitle;
    } else {
      document.title = this.defaultState.title;
    }

    // Check if localStorage sessionId exists, if not, generate a new one
    // let sessionId = localStorage.getItem("sessionId");
    let sessionId = this.props.fileId;
    // if (!sessionId) {
    //   sessionId = resetSessionId();
    //   localStorage.setItem("sessionId", sessionId);
    // }
    this.setState({ sessionId });

    let SOCKET_PORT;
    if (process.env.NODE_ENV === "production") {
      SOCKET_PORT = process.env.CLIENT_ORIGIN;
      // console.log(SOCKET_PORT, "In Production");
    } else {
      SOCKET_PORT = "http://localhost:8200";
      // console.log("Development");
    }

    const socket = io(SOCKET_PORT);

    socket.on("chatgptResChunk", (data) => {
      const { rambleBoxId, content, level, timeRequested, replace } = data;
      if (
        this.state.mostRecentLLMUpdate.get(rambleBoxId) &&
        this.state.mostRecentLLMUpdate.get(rambleBoxId)[level] > timeRequested
      ) {
        // console.log(this.state.mostRecentLLMUpdate.get(rambleBoxId), timeRequested);
        // console.log("Ignoring old response");
        return;
      }
      this.setState((prevState) => ({
        mostRecentLLMUpdate: prevState.mostRecentLLMUpdate.set(rambleBoxId, {
          ...prevState.mostRecentLLMUpdate.get(rambleBoxId),
          [level]: timeRequested,
        }),
        rambleBoxes: prevState.rambleBoxes.map(
          replace
            ? this.updateBox({
                id: rambleBoxId,
                content,
                level,
              })
            : this.updateBox({
                id: rambleBoxId,
                content: (prevState.lastClean.get(rambleBoxId) ?? "") + " " + content,
                level,
              })
        ),
      }));
    });
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.setState({
        activeRambleBox: -1,
        respeakingRambleBox: -1,
        isCustomPromptRecording: false,
        isSemanticSplitPromptRecording: false,
        isSemanticMergePromptRecording: false,
      });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fileId !== this.props.fileId) {
      // file changed; clear.
      // console.log("File changed; clearing data.");
      this.clearData({ clearLocal: false });
      this.setState((state) => ({
        sessionId: this.props.fileId,
        title: this.props.getTitle(),
        rambleBoxes: this.props.getRambles(),
      }));
      return;
    }
    this.saveLocalState(); // Save the title/boxes to local storage

    // The current recording message has changed
    const { currentRecordingMessage } = this.props;
    if (prevProps.currentRecordingMessage !== currentRecordingMessage) {
      // No message yet; ignore for now
      if (!currentRecordingMessage) return;
      // console.log("Current Recording Message:", currentRecordingMessage);

      // If we're still at the base message, clear it for now
      let { rambleBoxes, activeRambleBox, isCustomPromptRecording, isSemanticSplitPromptRecording, isSemanticMergePromptRecording} = this.state;

      if (isCustomPromptRecording) {
        this.setState({customPrompt: prevProps.currentRecordingMessage})
        return;
      } else if (isSemanticSplitPromptRecording) {
        this.setState({semanticSplitCustomPrompt: prevProps.currentRecordingMessage})
        return;
      } else if (isSemanticMergePromptRecording) {
        this.setState({semanticMergeCustomPrompt: prevProps.currentRecordingMessage})
        return;
      }

      const activeIndex = rambleBoxes.findIndex((r) => r.id === activeRambleBox);

      // No active ramble box (shouldn't happen, deleted before this point)
      if (activeIndex === -1) return;

      // logic for UI:A direct transcription
      if (this.state.UIVersion === "A") {
        this.streamWordsCursor(currentRecordingMessage, prevProps.currentRecordingMessage);
      }

      const currentText = rambleBoxes[activeIndex][0];
      if (currentText === this.baseMessage) {
        rambleBoxes[activeIndex][0] = "";
        this.setState({ rambleBoxes });
      }
    }
  }

  streamWordsCursor = (newWords, oldWords) => {
    const { cursorPosition } = this.state;
    let textarea = document.getElementById("uia-textfield");
    if (textarea) {
      let text = textarea.value;
      text =
        text.slice(0, cursorPosition) + newWords + text.slice(cursorPosition + oldWords.length);
      textarea.value = text;
      textarea.focus();
      textarea.setSelectionRange(cursorPosition, cursorPosition + newWords.length);
    }
  };

  updateBox =
    ({ id, content, level, keywords = null }) =>
    (box) => {
      if (box.id === id) {
        const newRambleBox = Object.assign({}, box);
        if (content === undefined || content === null) return newRambleBox;
        if (
          content.length > 2 &&
          content.charAt(0) === '"' &&
          content.charAt(content.length - 1) === '"'
        ) {
          newRambleBox[level] = content.slice(1, -1);
        } else {
          newRambleBox[level] = content;
        }
        if (keywords) newRambleBox.keywords = keywords;
        return newRambleBox;
      } else {
        return box;
      }
    };

  updateBoxKeywords = (rambleBoxId, keywords) => (box) => {
    const { areLLMToolsEnabled } = this.state;
    if (!areLLMToolsEnabled) return box;
    if (box.id === rambleBoxId) {
      const newRambleBox = Object.assign({}, box);
      const currentText = newRambleBox[1] ?? "";
      const currentWords = currentText ? currentText.split(" ").filter((s) => s.length > 0) : [];
      newRambleBox.keywords = Array.from(new Set([...newRambleBox.keywords, ...keywords]));
      // remove keywords no longer in text
      newRambleBox.keywords = newRambleBox.keywords.filter((keyword) =>
        currentWords.includes(keyword)
      );

      return newRambleBox;
    } else {
      return box;
    }
  };

  getRakeKeywordsFromText = (text) => {
    let keywords = rake(text, { language: "english" });
    const wordCount = text.split(" ").filter((s) => s.length > 0).length;
    let keywordCount = Math.floor(Math.log(wordCount)) + 1;

    keywords = keywords.length > keywordCount ? keywords.slice(0, keywordCount) : keywords;
    const individualKeywords = [];
    for (const keyword of keywords) {
      const keywordComponents = keyword.split(" ");
      individualKeywords.push(...keywordComponents);
    }

    const keywordsSet = new Set(individualKeywords);
    return Array.from(keywordsSet);
  };

  setRakeKeywords = (id) => {
    const { rambleBoxes } = this.state;
    const initialBox = rambleBoxes.find((box) => box.id === id);
    if (!initialBox) {
      this.handleSnackText(`No box found with id: ${id}`, "error");
      return;
    }
    const text = initialBox[1];

    this.setState((prevState) => ({
      rambleBoxes: prevState.rambleBoxes.map(
        this.updateBoxKeywords(id, this.getRakeKeywordsFromText(text))
      ),
    }));
  };

  generateRakeKeywords = () => {
    this.setState((prevState) => ({
      rambleBoxes: prevState.rambleBoxes.map((box) => {
        const keywords = this.getRakeKeywordsFromText(box[0]);
        return { ...box, keywords };
      }),
    }));
  };

  clearKeywords = () => {
    this.setState((prevState) => ({
      rambleBoxes: prevState.rambleBoxes.map((box) => {
        return { ...box, keywords: [] };
      }),
    }));
  };

  confirmClearData = () => {
    // A better version of this would be to reuse the internal modal. Simple for now though.
    if (window.confirm(`Are you sure you want to clear all data?`)) {
      this.clearData();
    }
  };

  clearData = (opts = { clearLocal: true }) => {
    // Retain UIVersion state when clearing data.
    const { UIVersion } = this.state;
    let uiVersionAStatePatch = {};
    if (UIVersion === "A") {
      uiVersionAStatePatch = {
        areBlockToolsEnabled: false,
        areLLMToolsEnabled: false,
        UIVersion: "A",
      };
    }
    this.setState({ ...this.defaultState, ...uiVersionAStatePatch });
    document.title = this.defaultState.title;

    if (opts.clearLocal) {
      localStorage.setItem("rambleBoxes", "");
      localStorage.setItem("rambleTitle", "");
      this.handleSnackText("Data cleared.", "info");
    }
  };

  // regenerateSessionId = () => {
  //   const newId = resetSessionId();
  //   localStorage.setItem("sessionId", newId);
  //   this.setState({ sessionId: newId });
  //   this.handleSnackText("Regenerated session ID.", "info");
  // };

  saveLocalState = () => {
    // If this gets too big, omit the summaries.
    const { title, rambleBoxes } = this.state;
    if (title !== this.props.getTitle()) {
      this.props.setTitle(title);
    }
    if (
      rambleBoxes !== this.props.getRambles() ||
      rambleBoxes.some((box, i) =>
        Object.keys(box).some((j) => box[j] !== this.props.getRambles()[i][j])
      )
    ) {
      this.props.setRambles(rambleBoxes);
    }
  };

  copyRambleBoxes = () => {
    let { rambleBoxes, viewLevel, UIVersion } = this.state;
    if (UIVersion === "A") rambleBoxes = [rambleBoxes[0]]; // Only copy the first box
    let text = rambleBoxes.map((box) => box[viewLevel]).join("\n\n");

    // Remove leading spaces from each line
    text = text.replace(/^ +/gm, "");
    navigator.clipboard.writeText(text);
    this.handleSnackText(`Copied all text to clipboard.`, "success");
  };

  exportTXT = () => {
    const { title, rambleBoxes, viewLevel } = this.state;
    let content = rambleBoxes.map((box) => box[viewLevel]).join("\n\n");
    // Remove leading spaces from each line
    content = content.replace(/^ +/gm, "");
    // console.log("Exporting:\n\n" + content);

    // Download ramble box text (like copy) but as txt file
    const filename = `${title}-${Date.now()}.txt`;
    downloadContent({ filename, content });

    this.handleSnackText(`Exported ${filename}.`, "success");
  };

  exportJSON = () => {
    // Form state object
    const { title, rambleBoxes } = this.state;
    const stateJSON = { title, rambleBoxes }; // TODO - potentially adding other things to save here?
    // console.log(stateJSON);

    // Download state as JSON file
    const content = JSON.stringify(stateJSON);
    const filename = `${title}-${Date.now()}.json`;
    downloadContent({
      filename,
      content,
    });

    this.handleSnackText(`Exported ${filename}.`, "success");
  };

  importJSON = () => {
    const fileInput = document.getElementById("files");
    if (fileInput) fileInput.click();
  };

  handleFileSelect = (evt) => {
    const [file] = evt.target.files;
    if (!file || !file.name) return;
    if (file.type.match("/json$")) {
      this.handleStateLoad(file); // Load State
    } else {
      console.error(`Load JSON files only.`);
    }
  };

  handleStateLoad = (file) => {
    this.clearData();
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      try {
        let json_object = JSON.parse(reader.result);
        // console.log(`Loading Example: ${file.name}`);
        this.setState(json_object);
        this.handleSnackText("Successfully loaded JSON.", "success");
      } catch (e) {
        console.error(e);
      }
    };
  };

  loadExample = () => {
    const title = "Can Computers Create Art?";
    this.clearData();
    this.handleSnackText("Loaded example data.", "success");
    document.title = title;
    this.setState(
      {
        title,
        rambleBoxes: exampleBoxes,
      },
      this.generateRakeKeywords
    );
  };

  loadExampleForStudy = () => {
    const { UIVersion } = this.state;
    if (UIVersion === "A") {
      const title = "Practicing Drawing from Photos vs Real Life";
      this.clearData();
      this.handleSnackText("Loaded A - Text Editor / ChatGPT Study Example.", "success");
      document.title = title;
      this.setState(
        {
          title,
          rambleBoxes: TextEditorExample,
        },
        this.generateRakeKeywords
      );
    } else if (UIVersion === "C") {
      const title = "Practicing Art through a Sketchbook";
      this.clearData();
      this.handleSnackText("Loaded C - LLM-Assisted Iterative Drafting Study Example.", "success");
      document.title = title;
      this.setState(
        {
          title,
          rambleBoxes: IDExample,
        },
        this.generateRakeKeywords
      );
    }
  };

  makeNewRambleBox = (baseMessage = "", level = 0) => {
    if (level === 1) {
      return {
        id: uuidv4(), // Avoid collision after creating multiple instantly
        0: baseMessage.trim(),
        1: baseMessage.trim(),
        2: "",
        3: "",
        4: "",
        keywords: [],
      };
    }
    return {
      id: uuidv4(), // Avoid collision after creating multiple instantly
      0: baseMessage.trim(),
      1: "",
      2: "",
      3: "",
      4: "",
      keywords: [],
    };
  };

  activateWord = (id) => (e) => {
    if (!this.state.areLLMToolsEnabled) return;
    let { rambleBoxes } = this.state;
    const index = rambleBoxes.findIndex((r) => r.id === id);
    if (index === -1) {
      return;
    }

    const rambleBoxKeywords = new Set(rambleBoxes[index].keywords) ?? new Set();
    let word = e.target.innerText.trim().toLowerCase();

    if (word in stopWordSet) {
      this.handleSnackText("You can't select a stop word!", "error");
    } else if (rambleBoxKeywords.has(word)) {
      rambleBoxKeywords.delete(word);
      rambleBoxes[index].keywords = Array.from(rambleBoxKeywords);
      this.setState({ rambleBoxes }, ()=> {
        historyapi.save("revision", "keyword-unhighlight", { keyword: word });
        historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
      });
    } else {
      rambleBoxKeywords.add(word);
      rambleBoxes[index].keywords = Array.from(rambleBoxKeywords);
      this.setState({ rambleBoxes }, ()=> {
        historyapi.save("revision", "keyword-highlight", { keyword: word });
        historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
      });
      
    }
  };

  addRambleBox = () => {
    const { activeRambleBox, respeakingRambleBox } = this.state;
    if (activeRambleBox) this.stopAndSaveSpeech();
    if (respeakingRambleBox !== -1) {
      this.scrollToId(respeakingRambleBox);
      this.handleSnackText("Cannot add a new box while respeaking.", "error");
      return;
    }

    const newRambleBox = this.makeNewRambleBox();

    this.setState(
      (prevState) => ({
        rambleBoxes: this.combineRambleBoxStates(
          prevState.rambleBoxes,
          [newRambleBox],
          prevState.rambleBoxes.length,
          (r) => true
        ),
        activeRambleBox: newRambleBox.id,
      }),
      () => {
        historyapi.save("organization", "new-ramble", {  });
        historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
        this.props.restartRecording();
        this.scrollToId(newRambleBox.id);
        this.handleSnackText("Starting recording.", "info");
      }
    );
  };

  addNewRambleOnIndex = async (baseMessage, index) => {
    const newRambleBox = this.makeNewRambleBox(baseMessage, 1);
    this.setState(
      (prevState) => ({
        rambleBoxes: this.combineRambleBoxStates(
          prevState.rambleBoxes,
          [newRambleBox],
          index,
          (r) => true
        ),
        llmOrManualMod: prevState.llmOrManualMod.set(newRambleBox.id, Date.now()),
      }),
      () => {
        this.setRakeKeywords(newRambleBox.id);
        this.updateMultiSummaryStream(newRambleBox.id, { requestClean: false });
      }
    );
  };

  scrollToId = (id) => {
    let active = document.getElementById(id);
    if (active) {
      active.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  recordSpeech = (id) => {
    const { activeRambleBox, UIVersion, rambleBoxes, respeakingRambleBox } = this.state;
    const index = rambleBoxes.findIndex((r) => r.id === id);

    // logic for first recording
    if (id === activeRambleBox) {
      return; // nothing to do here, though this shouldn't be possible
    }

    if (activeRambleBox !== -1) {
      this.stopAndSaveSpeech(); // TODO(jd): does this conflict with the setState below?
    } else {
      this.setState({ activeRambleBox: id });
    }

    // logic for raw text insertion
    if (UIVersion === "A") {
      let textarea = document.getElementById("uia-textfield");
      let isFocused = document.activeElement === textarea;
      let cursorPosition = textarea.value.length; // append when unfocused
      if (isFocused) cursorPosition = textarea.selectionStart; // else get cursor position
      this.setState({ activeRambleBox: id, cursorPosition });
    } else {
      // logic for respeaking
      if (respeakingRambleBox !== -1) {
        this.handleRespeak(respeakingRambleBox, "cancel");
      } else if (rambleBoxes[index][1].length > 0) {
        this.setState({ respeakingRambleBox: id, activeRambleBox: -1 }, ()=>{
          historyapi.save("revision", "respeak", {  });
          historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
        });
      }
    }

    this.props.restartRecording();
    this.handleSnackText("Starting recording.", "info");
  };

  stopAndSaveSpeech = () => {
    const { currentRecordingMessage, stopRecording } = this.props;
    const { activeRambleBox, rambleBoxes } = this.state;
    const activeIndex = rambleBoxes.findIndex((r) => r.id === activeRambleBox);
    if (activeIndex === -1) return;
    this.addToUndoStack();

    rambleBoxes[activeIndex][0] += currentRecordingMessage;
    this.setState({ rambleBoxes, activeRambleBox: -1 });
    // this.setRakeKeywords(activeRambleBox);
    // this.updateMultiSummaryStream(activeRambleBox);
    this.updateMultiSummaryStream(activeRambleBox, { shouldSetKeywords: true });
    stopRecording();
    this.handleSnackText("Stopped recording.", "info");
  };

  deleteSpeech = (id) => {
    let { rambleBoxes, viewLevel } = this.state;
    const index = rambleBoxes.findIndex((r) => r.id === id);
    if (index === -1) {
      this.handleSnackText(`Deleting box without id`, "error");
      return console.error(`Deleting box without id`);
    }
    const text = rambleBoxes[index][viewLevel];
    // A better version of this would be to reuse the internal modal. Simple for now though.
    if (text.split(" ").filter((s) => s.length > 0).length > 0) {
      if (!window.confirm(`Are you sure you want to delete this box?\n\nText:${text}`)) return;
    }
    this.addToUndoStack();
    rambleBoxes = rambleBoxes.filter((r) => r.id !== id);
    this.setState({ rambleBoxes }, () => {
      historyapi.save("organization", "delete-ramble", {  });
      historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
    });
  };

  handleRespeak = (id, action, ctx) => {
    if (id === -1) return;
    const { respeakingRambleBox, rambleBoxes } = this.state;
    const { currentRecordingMessage, stopRecording } = this.props;

    const respeakingIndex = rambleBoxes.findIndex((r) => r.id === respeakingRambleBox);
    let currentText = rambleBoxes[respeakingIndex][0];
    let newText = "";

    switch (action) {
      case "add":
        this.addToUndoStack();
        newText = currentText + " " + currentRecordingMessage;
        this.updateRambleText(id, newText);
        stopRecording();
        this.setState(
          (prevState) => ({
            lastClean: prevState.lastClean.set(id, rambleBoxes[respeakingIndex][1]),
          }),
          () => {
            llmapi
              .postStreamingResponseForSummary(
                currentRecordingMessage,
                1,
                rambleBoxes[respeakingIndex].keywords, // This is where user keywords get passed in
                "gpt-4",
                id,
                false
              )
              .then(() => {
                this.setState({ respeakingRambleBox: -1 }, () => {
                  this.setRakeKeywords(id);
                  this.updateMultiSummaryStream(id, { requestClean: false });
                  historyapi.save("revision", "respeak-add", { dictatedContent: currentRecordingMessage});
                  historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
                });
              });
          }
        );
        break;
      case "replace":
        this.addToUndoStack();
        newText = currentRecordingMessage;
        this.updateRambleText(id, newText);
        stopRecording();
        this.setState({ respeakingRambleBox: -1 }, () => {
          this.setRakeKeywords(id);
          this.updateMultiSummaryStream(id, { requestClean: true, replaceClean: false });
          historyapi.save("revision", "respeak-replace", { dictatedContent: currentRecordingMessage });
          historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
        });
        break;
      case "cancel":
        newText = currentText;
        this.updateRambleText(id, newText);
        stopRecording();
        this.setState({ respeakingRambleBox: -1 }, () => {
          historyapi.save("revision", "respeak-cancel", { dictatedContent: currentRecordingMessage});
          historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
        });
        break;
      case "insert":
        this.addToUndoStack();
        newText =
          currentText.slice(0, ctx.start).trim() +
          " " +
          currentRecordingMessage +
          " " +
          currentText.slice(ctx.end).trim();
        this.updateRambleText(id, newText);
        stopRecording();
        this.setState({ respeakingRambleBox: -1 }, () => {
          this.setRakeKeywords(id);
          this.updateMultiSummaryStream(id, { requestClean: true, replaceClean: false });
          historyapi.save("revision", "respeak-insert", {  });
          historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
        });
        break;
      default:
        return;
    }
  };

  onDragStart() {
    // Adds a bit of haptic feedback
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  }

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  handleReorder = (result) => {
    // Updates rambleBoxes array on reordering
    const { rambleBoxes } = this.state;

    if (result.combine) {
      this.mergeBoxes(result.draggableId, result.combine.draggableId);
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    this.addToUndoStack();
    const newRambleBoxes = this.reorder(rambleBoxes, result.source.index, result.destination.index);
    this.setState({ rambleBoxes: newRambleBoxes }, () => {
      historyapi.save("organization", "reorder-ramble", {  });
      historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
    });
  };

  updateMultiSummaryStream = async (id, opts = {}) => {
    const { areLLMToolsEnabled } = this.state;
    const defaultOpts = { requestClean: true, replaceClean: false, shouldSetKeywords: false };
    const actualOpts = Object.assign({}, defaultOpts, opts);
    const { requestClean, replaceClean, shouldSetKeywords } = actualOpts;
    if (!areLLMToolsEnabled) return;

    // console.log("Updating multi summary stream for box", id, rambleBoxes.find((box) => box.id === id))

    if (requestClean) {
      debounce(() => {
        this.requestSummaryStreaming(id, 1, replaceClean).then(() => {
          for (const level of [2, 3, 4]) {
            debounce(() => {
              this.requestSummaryStreaming(id, level);
            }, 150)();
          }
          if (shouldSetKeywords) {
            this.setRakeKeywords(id);
          }
        });
      }, 150)();
    } else {
      for (const level of [2, 3, 4]) {
        debounce(() => {
          this.requestSummaryStreaming(id, level);
        }, 150)();
      }
    }
  };

  requestSummaryStreaming = async (id, level, replace = true) => {
    const { rambleBoxes, areLLMToolsEnabled } = this.state;
    if (!areLLMToolsEnabled) return;

    const initialBox = rambleBoxes.find((box) => box.id === id);
    if (!initialBox) return console.error(`No box found with id: ${id}`);
    try {
      // console.log("Requesting summary for box", id, "at level", level);
      const res = await llmapi.postStreamingResponseForSummary(
        initialBox[level === 1 ? 0 : 1],
        level,
        initialBox.keywords, // This is where user keywords get passed in
        "gpt-4",
        id,
        replace
      );
      const content = res.data;
      this.setState(
        (prevState) => ({
          rambleBoxes: prevState.rambleBoxes.map(this.updateBox({ id, content, level })),
        }),
        () => {}
      );
      return;
    } catch (e) {
      console.error("Summary Streaming:", e);
    }
  };

  openEditWithLLM = async (id) => {
    this.setState({ LLMcommandTargetId: id, }, 
      () => {
        this.toggleCustomPromptSpeechMode(true)
        this.toggleCustomPromptModal()
      }
      );
  };

  splitRambleWithLLM = async (id) => {
    this.setState({ semanticSplitTarget: id }, () => {
      this.toggleSemanticSplitPromptSpeechMode(true);
      this.toggleSemanticSplitPromptModal();
    });
  }

  splitRambleDirectly = async (id) => {
    this.setState({ semanticSplitTarget: id }, () => {
      this.splitRamble();
    });
  }

  mergeRambleWithLLM = async () => {
    this.toggleSemanticMergePromptSpeechMode(true);
    this.toggleSemanticMergePromptModal();
  }
  

  applyLLMCommandToRambleBox = async () => {
    const {
      customPrompt,
      LLMcommandTargetId,
      rambleBoxes,
      areLLMToolsEnabled,
      useKeywordsWithCustomPrompt,
    } = this.state;
    const id = LLMcommandTargetId;
    if (!areLLMToolsEnabled) {
      this.handleSnackText(`LLM tools are not enabled.`, "error");
      return;
    }
    if (!customPrompt || customPrompt === "") {
      this.handleSnackText(`Empty text command!`, "error");
      return;
    }
    const initialBox = rambleBoxes.find((box) => box.id === id);
    if (!initialBox) {
      this.handleSnackText(`No box found with id: ${id}`, "error");
      return;
    }
    this.addToUndoStack();
    this.toggleCustomPromptModal();
    this.setState({ LLMcommandTargetId: -1 });
    const text = initialBox[1];
    const level = 1;
    const withKeywordSuffix = "\n Keywords: " + initialBox.keywords.join(", ");
    const res = await llmapi.callCustomPrompt(
      customPrompt + (useKeywordsWithCustomPrompt ? withKeywordSuffix : ""),
      text,
      {
        rambleBoxId: id,
        level,
      }
    ).then((res) => {
      return JSON.parse(res)
    }).catch((e) => {
      console.error(e);
      this.handleSnackText(`Failed to apply magic custom prompt.`, "error");
      return;
    });
    const oldIndex = rambleBoxes.findIndex((r) => r.id === id);

    let content
    try {
      if (res.type === 'single_ramble') {
        content = [res.ramble];
      } else { 
        content = res.rambles;
      }
    } catch (e) {

      this.handleSnackText(`Failed to apply magic custom prompt.`, "error"); 
      return;
    }
    const newBoxes = [];
    content.forEach((text) => {
      const box = this.makeNewRambleBox(text, 1);
      newBoxes.push(box);
    });
    this.addToUndoStack();

    this.setState(
      (prevState) => ({
        rambleBoxes: this.combineRambleBoxStates(
          prevState.rambleBoxes,
          newBoxes,
          oldIndex,
          (r) => r.id !== id
        ),
      }),
      () => {
        newBoxes.forEach((box) => {
          this.setRakeKeywords(box.id);
          this.updateMultiSummaryStream(box.id, { requestClean: false });
        });
        historyapi.save("revision", "magic-prompt", {          
          prompt: customPrompt,
          rambleContent: text,
          result: content,
        });
        historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes }) 
        this.handleSnackText(`Successfully applied magic custom prompt.`, "success"); 
      }
    );
  };

  // Used for merging two boxes using drag and drop
  mergeBoxes = async (
    sourceBox,
    targetBox,
    mergeFunction = (selectedText) => selectedText.join(" "),
    extraBoxes = [],
    wasLLM = false
  ) => {
    // If the source and target are the same, do nothing
    if (sourceBox === targetBox) return;
    this.setState({
      lastLLMUpdate: new Date(),
      mergingBoxIds: new Set([sourceBox, targetBox, ...extraBoxes]),
    });
    let { rambleBoxes, areLLMToolsEnabled, areBlockToolsEnabled, semanticMergeCustomPrompt } = this.state;
    if (!areBlockToolsEnabled && !areLLMToolsEnabled) return;

    this.handleSnackText("Starting merge.", "info");

    const selectedIds = [sourceBox, targetBox, ...extraBoxes];
    const select = (id) => rambleBoxes.findIndex((r) => r.id === id);
    const selectedIndices = selectedIds.map(select).filter((x) => x >= 0);
    selectedIndices.sort((a, b) => a - b); // sort ascending to maintain merge order
    const selectedText = selectedIndices.map((i) => rambleBoxes[i][1]);

    const mergedText = await mergeFunction(selectedText);

    const newRambleBox = this.makeNewRambleBox(mergedText, 1);
    const newIndex = Math.min(...selectedIndices);
    this.addToUndoStack();

    this.setState(
      (prevState) => ({
        rambleBoxes: this.combineRambleBoxStates(
          prevState.rambleBoxes,
          [newRambleBox],
          newIndex,
          (r) => r.id !== targetBox && !extraBoxes.includes(r.id) && r.id !== sourceBox
        ),
        mergingBoxIds: new Set(),
        llmOrManualMod: prevState.llmOrManualMod.set(newRambleBox.id, Date.now()),
      }),
      () => {
        this.setRakeKeywords(newRambleBox.id);
        this.updateMultiSummaryStream(newRambleBox.id, { requestClean: false });
        if (wasLLM) {
          historyapi.save("revision", "semantic-merge", {
            prompt: semanticMergeCustomPrompt,
            ramblesBefore: selectedText,
            ramblesAfter: mergedText,
          });
        } else {
          historyapi.save("revision", "manual-merge", {
            
            ramblesBefore: selectedText,
            ramblesAfter: mergedText,
          });
        }
        historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
      }
    );

    this.handleSnackText(
      wasLLM ? "Successful semantic merge." : "Successful manual merge.",
      "success"
    );
  };

  combineRambleBoxStates = (prevRambleBoxes, newRambleBoxes, newIndex, filterFunc) => {
    // Appending back into the rambleBoxes array
    let newRambleBoxState = prevRambleBoxes.filter(filterFunc);
    newRambleBoxState.splice(newIndex, 0, ...newRambleBoxes);
    return newRambleBoxState;
  };

  mergeBoxesWithLLM = (autoMergeBoxIds) => {
    if (autoMergeBoxIds.size < 2) {
      this.handleSnackText("Must select at least two boxes for semantic merging.", "error");
      return;
    }

    const { rambleBoxes, semanticMergeCustomPrompt } = this.state;
    const allBoxes = Array.from(autoMergeBoxIds);
    const targetBox = allBoxes[0];
    const sourceBox = allBoxes[1];
    let extraBoxes = [];
    if (allBoxes.length > 2) {
      extraBoxes = allBoxes.slice(2);
    }

    const selectedRambleBoxes = allBoxes
      .map((id) => rambleBoxes.find((r) => r.id === id))
      .filter((x) => x);
    const keywords = selectedRambleBoxes.map((box) => box.keywords).flat();

    const llmMergeFunction = async (selectedText) => await llmapi.mergeText(selectedText, semanticMergeCustomPrompt, keywords);

    this.setState({ autoMergeBoxIds: new Set() });
    return this.mergeBoxes(sourceBox, targetBox, llmMergeFunction, extraBoxes, true);
  };

  editRamble = (id) => {
    const { rambleBoxes, activeRambleBox, editingRambleBox } = this.state;

    // If there is an active RambleBox (a box currently being spoken into), save its content
    if (activeRambleBox !== -1) this.stopAndSaveSpeech();

    // Check if there's a RambleBox currently being edited and if it's different from the new one
    if (editingRambleBox !== -1 && editingRambleBox !== id) {
      const currentEditingBox = rambleBoxes.find((box) => box.id === editingRambleBox);
      let editedText = document.getElementById("editingRamble").innerText;
      if (currentEditingBox) {
        this.updateRambleText(editingRambleBox, editedText, 1);
      }
    }

    // Toggle the editing state
    if (editingRambleBox === id || id === -1) {
      this.setState({ editingRambleBox: -1 });
    } else {
      this.setState({
        viewLevel: 1,
        editingRambleBox: id,
      });
    }
  };

  updateRambleText = (id, text, viewLevel = 0) => {
    let { rambleBoxes } = this.state;
    let box = rambleBoxes.find((r) => r.id === id);
    if (!box) return console.error(`No box found with id: ${id}`);
    this.addToUndoStack();

    // Make a toast notification updated successfully.
    this.handleSnackText("Updated ramble.", "success");

    // Regenerate summaries for this box
    this.setState(
      (prevState) => ({
        rambleBoxes: prevState.rambleBoxes.map(
          this.updateBox({ id, content: text, level: viewLevel })
        ),
      }),
      () => {
        this.setState((prevState) => ({
          rambleBoxes: prevState.rambleBoxes.map(
            this.updateBoxKeywords(id, this.getRakeKeywordsFromText(text))
          ),
        }));
      }
    );
  };

  splitRamble = async () => {
    let { rambleBoxes, areLLMToolsEnabled, areBlockToolsEnabled, semanticSplitTarget, useKeywordsWithSemanticSplit } = this.state;
    if (!areBlockToolsEnabled && !areLLMToolsEnabled) return;
    const id = semanticSplitTarget;

    // Get the ramble text from the ID
    let { viewLevel, semanticSplitCustomPrompt } = this.state;
    const selected = rambleBoxes.find((r) => r.id === id);
    if (!selected) return console.error(`Splitting box without id`);
    const rawText = selected[viewLevel];
    this.setState({ splitBoxId: id });

    // Get the active words
    // const activeWordsArray = selected.keywords;

    // Determine wheter to split or segment based on active words
    let splitText = null;
    const keywords = selected.keywords

    this.handleSnackText("Starting split.", "info");
    splitText = await llmapi.segmentText(rawText, semanticSplitCustomPrompt, useKeywordsWithSemanticSplit ? keywords : []);
    try {
      splitText = JSON.parse(splitText).rambles;
    } catch {
      this.handleSnackText("Error parsing split text, please try again.", "error");
      this.setState({ splitBoxId: null });
      return;
    }

    // Delete the old ramble box
    const oldIndex = rambleBoxes.findIndex((r) => r.id === id);

    // Add new original boxes in its place
    const newBoxes = [];
    splitText.forEach((text) => {
      const box = this.makeNewRambleBox(text, 1);
      newBoxes.push(box);
    });
    this.addToUndoStack();

    this.setState(
      (prevState) => ({
        rambleBoxes: this.combineRambleBoxStates(
          prevState.rambleBoxes,
          newBoxes,
          oldIndex,
          (r) => r.id !== id
        ),
        splitBoxId: null,
        llmOrManualMod: (() => {
          for (const box of newBoxes) {
            prevState.llmOrManualMod.set(box.id, Date.now());
          }
          return prevState.llmOrManualMod;
        })(),
      }),
      () => {
        // Update summaries for new boxes
        newBoxes.forEach((box) => {
          this.setRakeKeywords(box.id);
          this.updateMultiSummaryStream(box.id, { requestClean: false });
        });
        historyapi.save("revision", "semantic-split", {
          prompt: semanticSplitCustomPrompt,
          ramblesBefore: rawText,
          ramblesAfter: splitText,
        });
        historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })  
      }
    );
    this.handleSnackText("Successful semantic split", "success");
  };

  formatText = async (id) => {
    const rambleBoxes = this.state.rambleBoxes;
    const rambleBox = rambleBoxes.find((r) => r.id === id);
    if (rambleBox === undefined) {
      return;
    }
    const rambleBoxText = rambleBox[0];
    const formattedText = await llmapi.formatText(rambleBoxText);

    rambleBoxes.find((r) => r.id === id)[0] = formattedText;
    this.setState({ rambleBoxes });
  };

  toggleSettingsModal = () => {
    this.setState((prevState) => ({ isSettingsModalOpen: !prevState.isSettingsModalOpen }));
  };

  toggleCustomPromptModal = () => {
    const { isCustomPromptModalOpen } = this.state;
    if (isCustomPromptModalOpen) {
      // open -> close
      this.toggleCustomPromptSpeechMode(false);
      this.setState({ isCustomPromptModalOpen: false, LLMcommandTargetId: -1 });
    } else {
      // close -> open
      this.setState({ isCustomPromptModalOpen: true });
    }
  };

  toggleSemanticSplitPromptModal = () => {
    const { isSemanticSplitModalOpen } = this.state;
    if (isSemanticSplitModalOpen) {
      // open -> close
      this.toggleSemanticSplitPromptSpeechMode(false);
      this.setState({ isSemanticSplitModalOpen: false, semanticSplitTarget: -1 });
    } else {
      // close -> open
      this.setState({ isSemanticSplitModalOpen: true });
    }
  };

  toggleSemanticMergePromptModal = () => {
    const { isSemanticMergeModalOpen } = this.state;
    if (isSemanticMergeModalOpen) {
      // open -> close
      this.toggleSemanticMergePromptSpeechMode(false);
      this.setState({ isSemanticMergeModalOpen: false });
    } else {
      // close -> open
      this.setState({ isSemanticMergeModalOpen: true });
    }
  };

  updateUIVersion = ({ target }) => {
    const UIVersion = target.value;
    let areLLMToolsEnabled = false;
    let areBlockToolsEnabled = false;
    let activeRambleBox = -1;
    let editingRambleBox = -1;
    let viewLevel = 1;

    // Save it into localStorage to persist across reloads.
    localStorage.setItem("UIVersion", UIVersion);
    historyapi.context.UIVersion = UIVersion;

    if (UIVersion === "A") {
      // console.log("Activating UI Version A");
      this.clearKeywords();
    } else if (UIVersion === "B") {
      // console.log("Activating UI Version B");
      this.clearKeywords();
      areBlockToolsEnabled = true;
    } else if (UIVersion === "C") {
      this.generateRakeKeywords();
      // console.log("Activating UI Version C");
      areBlockToolsEnabled = true;
      areLLMToolsEnabled = true;
    }

    // Change UI version, clear active ramble box and related data.
    this.setState({
      UIVersion,
      areLLMToolsEnabled,
      activeRambleBox,
      editingRambleBox,
      areBlockToolsEnabled,
      viewLevel,
    });
  };

  toggleCustomPromptSpeechMode = (isCustomPromptRecording) => {
    if (isCustomPromptRecording) {
      this.props.startRecording();
      historyapi.save("revision", "magic-prompt-dictate", {  });
    } else {
      this.props.stopRecording();
    }
    this.setState({ isCustomPromptRecording });
  };

  toggleSemanticSplitPromptSpeechMode = (isSemanticSplitPromptRecording) => {
    if (isSemanticSplitPromptRecording) {
      this.props.startRecording();
      historyapi.save("revision", "semantic-split-dictate", {  });
    } else {
      this.props.stopRecording();
    }
    this.setState({ isSemanticSplitPromptRecording });
  };

  toggleSemanticMergePromptSpeechMode = (isSemanticMergePromptRecording) => {
    if (isSemanticMergePromptRecording) {
      this.props.startRecording();
      historyapi.save("revision", "semantic-merge-dictate", {  });
    } else {
      this.props.stopRecording();
    }
    this.setState({ isSemanticMergePromptRecording });
  };

  handleZoomChange = ({ target }) => {
    const viewLevel = +target.value;
    this.setState({ viewLevel });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackOpen: false });
  };

  handleSnackText = (text, severity) => {
    this.setState({ snackOpen: true, snackText: text, severity });
  };

  wordCount = (rambleBoxes, currentRecordingMessage) => {
    const { viewLevel } = this.state;
    let words = 0;

    if (this.state.UIVersion === "A") {
      if (rambleBoxes.length > 0) {
        rambleBoxes = [rambleBoxes[0]];
      } else {
        rambleBoxes = [];
      }
    }

    if (rambleBoxes.length === 0) return words;

    rambleBoxes.forEach((rambleBox) => {
      const potentialWords = rambleBox ? rambleBox[viewLevel].split(" ") : [];
      words += potentialWords.filter((word) => word !== "").length;
    });
    words += currentRecordingMessage.split(" ").filter((word) => word !== "").length;
    return words;
  };

  addToUndoStack = () => {
    const { undoStack, rambleBoxes } = this.state;
    undoStack.push(rambleBoxes);
    this.setState({ undoStack, redoStack: [] });
  };

  handleUndo = () => {
    const {rambleBoxes, undoStack, redoStack} = this.state;
    if (undoStack.length === 0) return;
    const lastRambleBoxState = undoStack.pop();
    redoStack.push(rambleBoxes);
    this.setState({rambleBoxes: lastRambleBoxState, undoStack, redoStack}, () => {
      historyapi.save('state', 'undo')
      historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
    });
  }

  handleRedo = () => {
    const {rambleBoxes, undoStack, redoStack} = this.state;
    if (redoStack.length === 0) return;
    const nextRambleBoxState = redoStack.pop();
    undoStack.push(rambleBoxes);
    this.setState({rambleBoxes: nextRambleBoxState, undoStack, redoStack}, () => {
      historyapi.save('state', 'redo')
      historyapi.save('state', 'ramble-content', { content: this.state.rambleBoxes })
    });
  }

  renderHeader = () => {
    const { rambleBoxes, UIVersion } = this.state;
    const { currentRecordingMessage } = this.props;
    return (
      <nav>
        {this.renderTitle()}
        <div className="upperControls">
          <span>{this.wordCount(rambleBoxes, currentRecordingMessage)} words</span>
          <Button onClick={this.copyRambleBoxes}>
            {UIVersion === "A" ? "Copy Text" : "Copy All Text"}
          </Button>
          <Button onClick={this.toggleSettingsModal}>
            <Settings />
          </Button>
        </div>
      </nav>
    );
  };

  renderTitle = () => {
    const { title, editingTitle } = this.state;
    const toggle = (confirm = false) => {
      if (editingTitle) {
        if (confirm) {
          let newTitle = document.getElementById("edit-title-input").value;
          if (newTitle === "") newTitle = this.defaultState.title;
          this.setState({ title: newTitle });
          // localStorage.setItem("rambleTitle", newTitle);
          this.props.setTitle(newTitle);
        }
      } else {
        setTimeout(() => {
          // Focus the input after it renders
          const input = document.getElementById("edit-title-input");
          if (input) {
            if (input.value === this.defaultState.title) {
              input.value = "";
            }
            input.focus();
            input.select();
          }
        }, 50);
      }

      // Toggle the editing state
      this.setState({ editingTitle: !editingTitle });
    };

    const checkEnter = (event) => {
      if (event.keyCode === 13) toggle(true); // keycode for enter
    };

    if (!editingTitle) {
      return (
        <h1 className="edit-title" onClick={toggle}>
          {title}
        </h1>
      );
    } else {
      return (
        <h1>
          <input
            type="text"
            id="edit-title-input"
            defaultValue={title}
            onKeyDown={checkEnter}
            onBlur={() => toggle(true)}
          />
        </h1>
      );
    }
  };

  renderAutoMerge = (handleSnackText, mergeRambleWithLLM) => {
    const { areLLMToolsEnabled, autoMergeBoxIds } = this.state;
    if (!areLLMToolsEnabled) return null;
    return (
      <LongAndShortPressButton
        longPressAction={() => {
          handleSnackText("Listening for semantic merge prompt.", "success")
          mergeRambleWithLLM()
        }}
        shortPressAction={
          () => {
            this.mergeBoxesWithLLM(autoMergeBoxIds);
          }
        }
        isDisabled={autoMergeBoxIds.size < 2}
        icon={<CallMerge />}
        text={"Semantic Merge"}
      />
    );
  };

  renderRambleBoxes = () => {
    const { currentRecordingMessage } = this.props;
    const {
      rambleBoxes,
      viewLevel,
      activeRambleBox,
      editingRambleBox,
      respeakingRambleBox,
      splitBoxId,
      mergingBoxIds,
      autoMergeBoxIds,
      areLLMToolsEnabled,
      UIVersion,
      llmOrManualMod,
    } = this.state;

    let shownRambles = rambleBoxes;
    let basic = false;
    if (UIVersion === "A") {
      if (rambleBoxes.length > 0) {
        shownRambles = [rambleBoxes[0]];
        basic = true;
      } else {
        const newRambleBox = this.makeNewRambleBox();
        this.setState((prevState) => ({
          rambleBoxes: this.combineRambleBoxStates(
            prevState.rambleBoxes,
            [newRambleBox],
            prevState.rambleBoxes.length,
            (r) => true
          ),
        }));
      }
    }
    if (UIVersion === "B") {
      basic = true;
    }

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.handleReorder}>
        <Droppable droppableId="droppable" isCombineEnabled={true}>
          {(provided) => (
            <div id="rambleBoxContainer" {...provided.droppableProps} ref={provided.innerRef}>
              {shownRambles.map((rambleBox, index) => {
                if (!rambleBox) return null;
                const id = rambleBox.id;
                const active = id === activeRambleBox;
                const editing = id === editingRambleBox;
                const respeaking = id === respeakingRambleBox;
                let rambleView = rambleBox[viewLevel];
                if (active && !respeaking) rambleView += currentRecordingMessage;

                // // If the summary viewLevel is shorter than actual transcript, just display the cleaned up transcript
                // const lengthLimits = { 2: 20, 3: 10, 4: 5 };
                // const wordCount = rambleBox[0].split(" ").length;
                // if (lengthLimits[viewLevel] && wordCount <= lengthLimits[viewLevel]) {
                //   rambleView = rambleBox[1];
                // }

                let keywords = new Set();
                if (rambleBox.keywords && rambleBox.keywords.length > 0) {
                  keywords = new Set(rambleBox.keywords);
                }

                const addAutoMergeId = (isAdd, id) => {
                  if (isAdd) {
                    this.setState({ autoMergeBoxIds: autoMergeBoxIds.add(id) });
                  } else {
                    const autoMergeBoxIdsCopy = new Set(autoMergeBoxIds);
                    autoMergeBoxIdsCopy.delete(id);
                    this.setState({ autoMergeBoxIds: autoMergeBoxIdsCopy });
                  }
                };

                const addNewRamble = (baseMessage, index) =>
                  this.addNewRambleOnIndex(baseMessage, index);

                const isRecentMergeOrSplitResult =
                  llmOrManualMod.has(id) && llmOrManualMod.get(id) > Date.now() - 5000;

                return (
                  <RambleBox
                    id={id}
                    index={index}
                    key={`ramblebox-${id}`}
                    text={rambleView}
                    viewLevel={viewLevel}
                    basic={basic}
                    active={active}
                    editing={editing}
                    areLLMToolsEnabled={areLLMToolsEnabled}
                    respeaking={respeaking}
                    activeWords={keywords}
                    addNewRamble={addNewRamble}
                    activateWord={this.activateWord}
                    editRamble={() => this.editRamble(id)}
                    splitRamble={()=> this.splitRambleWithLLM(id)}
                    recordSpeech={() => this.recordSpeech(id)}
                    deleteSpeech={() => this.deleteSpeech(id)}
                    stopSpeech={() => this.stopAndSaveSpeech()}
                    updateRamble={(text, viewLevel = 1) =>
                      this.updateRambleText(id, text, viewLevel)
                    }
                    addToLLMOrManualMod={(id) => {
                      this.setState((prevState) => ({
                        llmOrManualMod: prevState.llmOrManualMod.set(id, Date.now()),
                      }));
                    }}
                    removeFromLLMOrManualMod={(id) => {
                      const { llmOrManualMod } = this.state;
                      llmOrManualMod.delete(id);
                      this.setState({ llmOrManualMod });
                    }}
                    handleRespeak={(action, ctx) => this.handleRespeak(id, action, ctx)}
                    openEditWithLLM={() => this.openEditWithLLM(id)}
                    currentRecordingMessage={currentRecordingMessage}
                    mergeBoxes={(sourceBox, targetBox) => this.mergeBoxes(sourceBox, targetBox)}
                    isDisabled={id === splitBoxId || mergingBoxIds.has(id)}
                    isRecentMergeOrSplitResult={isRecentMergeOrSplitResult}
                    autoMergeSelect={(e) => addAutoMergeId(e.target.checked, id)}
                    updateSummary={() => {
                      this.updateMultiSummaryStream(id, { requestClean: false }).then(() => {
                        historyapi.save('state', 'ramble-content', { content: rambleBoxes })  
                      });
                    }}
                    isLLMtarget={id === this.state.LLMcommandTargetId}
                    UIVersion={UIVersion}
                    handleSnackText={this.handleSnackText}
                    pId={this.props.pId}
                    rambleBoxes={rambleBoxes}
                    addToUndoStack={this.addToUndoStack}
                    splitRambleDirectly={() => this.splitRambleDirectly(id)}
                    autoMergeBoxIds={autoMergeBoxIds}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderSlidingWindow = () => {
    const windowLength = 9;
    const { activeRambleBox, showSlidingWindow } = this.state;
    if (activeRambleBox !== -1 || showSlidingWindow) {
      const { currentRecordingMessage } = this.props;
      let lastWords = currentRecordingMessage.split(" ").slice(-windowLength).join(" ");
      return (
        <div className="slidingWindow" onClick={() => this.scrollToId(activeRambleBox)}>
          {" "}
          <div className="speech-text">{lastWords}</div>{" "}
        </div>
      );
    } else {
      return null;
    }
  };

  renderSettingsModal = () => {
    const { isSettingsModalOpen, UIVersion } = this.state;
    const pId = this.props.pId;
    const runAndClose = (fn) => {
      if (fn) fn();
      this.toggleSettingsModal();
    };

    return (
      <Modal
        open={isSettingsModalOpen}
        onClose={this.toggleSettingsModal}
        aria-labelledby="settings-modal-title"
        aria-describedby="settings-modal-description"
      >
        <Box className="modal-box">
          <Box className="modal-header">
            <h2 id="settings-modal-title">Settings</h2>
            <IconButton
              aria-label="close"
              className="pull-right"
              onClick={this.toggleSettingsModal}
            >
              <Cancel />
            </IconButton>
          </Box>

          <hr />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">UI Version</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={this.updateUIVersion}
              value={UIVersion}
            >
              {/* <FormControlLabel value="A" control={<Radio />} label="Text and Speech Editor" /> */}
              {/* <FormControlLabel
                value="B"
                control={<Radio />}
                label="B: Manual Iterative Drafting"
              /> */}
              <FormControlLabel
                value="C"
                control={<Radio />}
                label="LLM-assisted Iterative Drafting"
              />
            </RadioGroup>
          </FormControl>

          <hr />
          <FormLabel id="demo-row-radio-buttons-group-label">App Data</FormLabel>
          <Button onClick={() => runAndClose(this.exportTXT)}>Download Text File</Button>
          {/* <Button onClick={this.loadExample}>Load Example Data</Button> */}
          <Button onClick={this.loadExampleForStudy}>Load Study Example Data</Button>
          <Button onClick={this.confirmClearData}>Clear Data</Button>
          <Button onClick={() => runAndClose(this.exportJSON)}>Export JSON</Button>
          <input id="files" type="file" onChange={this.handleFileSelect} multiple="" />
          <Button onClick={this.importJSON}>Import JSON</Button>

          <hr />
          <FormLabel id="demo-row-radio-buttons-group-label">Participant ID</FormLabel>
          {/* <Button onClick={this.regenerateSessionId}>Regenerate</Button> */}
          <small className="pull-right">{pId}</small>
        </Box>
      </Modal>
    );
  };

  renderCustomPromptModal = () => {
    const {
      isCustomPromptModalOpen,
      customPrompt,
      useKeywordsWithCustomPrompt,
      isCustomPromptRecording,
    } = this.state;

    return this.renderConfirmationModal({
      isModalOpen: isCustomPromptModalOpen,
      toggleModal: this.toggleCustomPromptModal,
      toggleSpeech: () => this.toggleCustomPromptSpeechMode(!isCustomPromptRecording),
      turnOffSpeech: () => {
        this.toggleCustomPromptSpeechMode(false)
      },
      modalInputContent: customPrompt,
      modalInputContentOnChange: (e) => this.setState({ customPrompt: e.target.value }),
      useKeywords: useKeywordsWithCustomPrompt,
      isRecording: isCustomPromptRecording,
      onApply: this.applyLLMCommandToRambleBox,
      titleText: "LLM Commands",
      toggleUseKeywords: () => this.setState((prevState) => ({
        useKeywordsWithCustomPrompt: !prevState.useKeywordsWithCustomPrompt,
      })),
      applyIcon: <AutoFixHigh />
    })
  };

  renderSemanticSplitModal = () => {
    const {
      isSemanticSplitModalOpen,
      semanticSplitCustomPrompt,
      useKeywordsWithSemanticSplit,
      isSemanticSplitPromptRecording,
    } = this.state;

    return this.renderConfirmationModal({
      isModalOpen: isSemanticSplitModalOpen,
      toggleModal: this.toggleSemanticSplitPromptModal,
      toggleSpeech: () => this.toggleSemanticSplitPromptSpeechMode(!isSemanticSplitPromptRecording),
      turnOffSpeech: () => {
        this.toggleSemanticSplitPromptSpeechMode(false)
      },
      modalInputContent: semanticSplitCustomPrompt,
      modalInputContentOnChange: (e) => this.setState({ semanticSplitCustomPrompt: e.target.value }),
      useKeywords: useKeywordsWithSemanticSplit,
      isRecording: isSemanticSplitPromptRecording,
      onApply: () => {
        this.toggleSemanticSplitPromptModal()
        this.splitRamble()
      },
      titleText: "Semantic Split",
      toggleUseKeywords: () => this.setState((prevState) => ({
        useKeywordsWithSemanticSplit: !prevState.useKeywordsWithSemanticSplit,
      })),
      applyIcon: <ContentCut />
    })
  };

  renderSemanticMergeModal = () => {
    const {
      isSemanticMergeModalOpen,
      semanticMergeCustomPrompt,
      useKeywordsWithSemanticMerge,
      isSemanticMergePromptRecording,
      autoMergeBoxIds
    } = this.state;

    return this.renderConfirmationModal({
      isModalOpen: isSemanticMergeModalOpen,
      toggleModal: this.toggleSemanticMergePromptModal,
      toggleSpeech: () => {
        this.toggleSemanticMergePromptSpeechMode(!isSemanticMergePromptRecording)
      },
      turnOffSpeech: () => {
        this.toggleSemanticMergePromptSpeechMode(false)
      },
      modalInputContent: semanticMergeCustomPrompt,
      modalInputContentOnChange: (e) => this.setState({ semanticMergeCustomPrompt: e.target.value }),
      useKeywords: useKeywordsWithSemanticMerge,
      isRecording: isSemanticMergePromptRecording,
      onApply: () => {
        this.toggleSemanticMergePromptModal()
        this.mergeBoxesWithLLM(autoMergeBoxIds)
      },
      titleText: "Semantic Merge",
      toggleUseKeywords: () => this.setState((prevState) => ({
        useKeywordsWithSemanticMerge: !prevState.useKeywordsWithSemanticMerge,
      })),
      applyIcon: <CallMerge />
    })
  };

  renderConfirmationModal = (
    {
      isModalOpen,
      toggleModal,
      toggleSpeech,
      modalInputContent,
      modalInputContentOnChange, // (e) => this.setState({ modalInputContent: e.target.value })
      useKeywords,
      isRecording,
      onApply,
      titleText,
      toggleUseKeywords,
      applyIcon,
      turnOffSpeech
    }) => {

    return (
      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="settings-modal-title"
        aria-describedby="settings-modal-description"
      >
        <Box className="modal-box">
          <Box className="modal-header">
            <h2 id="settings-modal-title">{titleText}</h2>
            <IconButton
              aria-label="close"
              className="pull-right"
              onClick={toggleModal}
            >
              <Cancel />
            </IconButton>
          </Box>

          <FormControl className="custom-prompt-modal-content">
            <Box className="custom-prompt-modal-input-row">
              <TextField
                sx={
                    isRecording
                    ? {
                        width: "100%",
                        backgroundColor: "#ffdfdf",
                      }
                    : { width: "100%" }
                }
                id="custom-prompt"
                multiline
                defaultValue={modalInputContent}
                placeholder="Specify desired text changes"
                onChange={modalInputContentOnChange}
                onFocus={turnOffSpeech}
                maxRows={10}
              />
                <Button
                  onClick={toggleSpeech}
                >
                  <Mic
                    sx={
                        isRecording
                        ? {
                            color: "#ff0101",
                          }
                        : {}
                    }
                  />
                </Button>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useKeywords}
                  onChange={toggleUseKeywords}
                  inputProps={{ "aria-label": "controlled" }}
                  label="Include ramble keywords in prompt"
                />
              }
              label="Include keywords as context"
              labelPlacement="end"
            />
          </FormControl>
          <Button
            onClick={onApply}
            endIcon={applyIcon}
          >
            Apply
          </Button>
        </Box>
      </Modal>
    );
  };


  renderFooter = () => {
    const { viewLevel, areLLMToolsEnabled, UIVersion, editingRambleBox, undoStack, redoStack } = this.state;
    if (UIVersion === "A") return;
    const isEditing = editingRambleBox !== -1;

    const marks = [
      {
        value: 0,
        label: "Raw",
      },
      {
        value: 1,
        label: "Full",
      },
      {
        value: 2,
        label: "50%",
      },
      {
        value: 3,
        label: "25%",
      },
      {
        value: 4,
        label: "10%", // just to make it look nice
        // label: "5W",
      },
    ];

    return (
      <div className="footer">
        <div className="slider-container">
          {this.renderSlidingWindow()}
          <div className="controls">
            <div className="controls-sub">
              {areLLMToolsEnabled && this.renderAutoMerge(
                this.handleSnackText,
                this.mergeRambleWithLLM
              )}
              <Button variant="outlined" onClick={this.addRambleBox} startIcon={<Add />}>
                Ramble
              </Button>
            </div>
            {areLLMToolsEnabled && (
              <Fragment>
                <div className="break"></div>
                <Box className="slider">
                  <Slider
                    aria-label="Zoom"
                    value={viewLevel}
                    onChange={this.handleZoomChange}
                    step={1}
                    track={false}
                    marks={marks}
                    min={1}
                    max={4}
                    valueLabelDisplay="off"
                    disabled={isEditing}
                  />
                </Box>
              </Fragment>
            )}
            <div className="controls-sub">
              <Button onClick={this.handleUndo} startIcon={<Undo />} disabled={undoStack.length === 0}>
                Undo
              </Button>
              <Button onClick={this.handleRedo} startIcon={<Redo />} disabled={redoStack.length === 0}>
                Redo
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSnack = () => {
    return (
      <Snackbar open={this.state.snackOpen} autoHideDuration={6000} onClose={this.handleSnackClose}>
        <Alert severity={this.state.severity} onClose={this.handleSnackClose}>
          {this.state.snackText}
        </Alert>
      </Snackbar>
    );
  };

  render() {
    return (
      <div id="RambleEditor">
        {this.renderHeader()}
        {this.renderRambleBoxes()}
        {this.renderSettingsModal()}
        {this.renderCustomPromptModal()}
        {this.renderSemanticSplitModal()}
        {this.renderSemanticMergeModal()}
        {this.renderFooter()}
        {this.renderSnack()}
      </div>
    );
  }
}

RambleEditor = withDictation(RambleEditor);

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [], // [{ id: localStorage.getItem('sessionId'), title: localStorage.getItem('rambleTitle'), rambles: JSON.parse(localStorage.getItem('rambleBoxes')) }],
      showFileList: false,
    };
    this.state.selectedFile = this.state.fileList[0];
  }

  componentDidMount = () => {
    // get pId from local storage, or prompt if missing
    this.updateFileList();
  };

  updateFileList = async () => {
    // fetch from server
    let fileList = await fetch(`/api/files?pId=${this.props.pId}`, {
      method: "GET",
    }).then((res) => res.json());
    // if no files, create a new one
    if (fileList.length === 0) {
      fileList = [{ id: uuidv4(), title: "Untitled", rambles: [] }];
    }
    const selectedFileId = localStorage.getItem("selectedFileId");
    let selectedFile = fileList[0]; // Default to the first file
    // select the file from local storage if it exists
    if (selectedFileId) {
      const foundFile = fileList.find((file) => file.id === selectedFileId);
      if (foundFile) {
        selectedFile = foundFile;
      }
    }
    this.setState({ fileList, selectedFile });
  };

  addFile = () => {
    let file = { id: uuidv4(), title: "Untitled" };
    this.setState({ fileList: [...this.state.fileList, file], selectedFile: file });
  };

  saveFile = (file) => {
    // save to server
    fetch(`/api/files?pId=${this.props.pId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
    });
    localStorage.setItem("selectedFileId", file.id);
    let newFiles = this.state.fileList.map((f) => (f.id === file.id ? file : f));
    this.setState({ fileList: newFiles, selectedFile: file });
    // localStorage.setItem("rambleBoxes", JSON.stringify(file.rambles));
    // localStorage.setItem("rambleTitle", file.title);
    // localStorage.setItem("sessionId", file.id);
  };

  selectFile = (file) => {
    this.setState({ selectedFile: file });
    localStorage.setItem("selectedFileId", file.id);
  };

  render() {
    return (
      <div className="App">
        {this.state.showFileList ? (
          <div className="file-list">
            <button className="close-button" onClick={() => this.setState({ showFileList: false })}>
              &times;
            </button>
            <h3>Saved Files</h3>
            {this.state.fileList.map((file) => (
              <div
                className={`file-list-item ${file === this.state.selectedFile && "selected"}`}
                onClick={() => this.selectFile(file)}
              >
                {file.title}
              </div>
            ))}
            <button className="new-button" onClick={this.addFile}>
              + New file &raquo;
            </button>
          </div>
        ) : (
          <div className="file-list-button" onClick={() => this.setState({ showFileList: true })}>
            ꠵
          </div>
        )}
        {this.state.selectedFile && (
          <RambleEditor
            fileId={this.state.selectedFile.id}
            getRambles={() => this.state.selectedFile?.rambles || []}
            setRambles={(rambles) => this.saveFile({ ...this.state.selectedFile, rambles })}
            getTitle={() => this.state.selectedFile?.title || "Untitled"}
            setTitle={(title) => this.saveFile({ ...this.state.selectedFile, title })}
            pId={this.props.pId}
          />
        )}
      </div>
    );
  }
}

const pIdRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pId, setPId] = React.useState(searchParams.get("pId"));

  const isPIdValid = React.useMemo(() => Boolean(pId && pId.match(pIdRegex)), [pId]);

  React.useEffect(() => {
    if (isPIdValid) {
      localStorage.setItem("pId", pId);
    } else {
      const savedPId = localStorage.getItem("pId");
      // console.log("ff", savedPId)
      let newPId;
      if (savedPId && savedPId.match(pIdRegex)) {
        newPId = savedPId;
      } else {
        newPId = prompt("Please enter your participant ID");
        // if not a valid uuidv4-formatted string, make up a new one.
        if (!newPId || !newPId.match(pIdRegex)) {
          newPId = uuidv4();
          alert("Invalid ID, using " + newPId + " instead.");
        }
      }

      setPId(newPId);
      setSearchParams({ pId: newPId });
      localStorage.setItem("pId", newPId);
    }
  }, [searchParams, setSearchParams, pId, setPId, isPIdValid]);

  return isPIdValid ? <MainPage pId={pId} /> : null;
}

export { App };
