import React from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import useLongPress from "./useLongPress";

export function LongAndShortPressButton(
    {
      isDisabled,
      shortPressAction,
      longPressAction,
      icon,
      provided = null,
      text = null
    }
  ) {
  
    const onLongPress = () => {
      longPressAction();
    };
  
    const onClick = () => {
      shortPressAction()
    }
  
    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    
    if (!text) {
      if (!provided) {
        return (
          <IconButton 
            {...longPressEvent}
            disabled={isDisabled}
          >
            {icon}
          </IconButton>
        )
      }
      return (
        <IconButton 
          {...longPressEvent}
          data-rbd-drag-handle-context-id={provided.dragHandleProps?.["data-rbd-drag-handle-context-id"]}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
              // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
              cursor: "auto"
          }}
          disabled={isDisabled}
        >
          {icon}
        </IconButton>
      )
    }

    return (
      <Button 
        {...longPressEvent}
        disabled={isDisabled}
        startIcon={icon}
      >
        {text}
      </Button>
    )
  }
  